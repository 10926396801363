import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import '../src/PartumBillPrint';
import '../src/FileCreate';
import '../src/PartumBillPrint.css';
import { Home } from './components/Home';
import history from '../src/History';

class HomePage extends Component
{   
    static displayName = HomePage.name;
    render() {
        return (
            <div>
                <button className="button2" onClick={() => history.push('/FileCreation')}> Click</button>
                <button className="button1" onClick={() => history.push('/BillPrint')}> Print</button>
               

              
            </div>
        );
    }
   
  
}
export default HomePage;


      


    