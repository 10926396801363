import React, { Component } from 'react';
import { Router, Switch, Route } from "react-router-dom";
import history from './History';
import HomePage from '../src/Home';
//import BillPrint from '../src/PartumBillPrint';
import Bill from '../src/PartumBillPrint';
import FileCreation from '../src/FileCreate';
import BillPrintValid from '../src/BillPrintValid';
import Error from '../src/Error';

export default class Routes extends Component {
    render() {
    
        return (
            <Router history={history}>
                <Switch>
                    <Route path="/" exact component={FileCreation} />
                    {/*<Route path="/BillPrint" component={BillPrint} />*/}
                    <Route path="/Bill" component={Bill} />
                    <Route path="/BillPrintValid" component={BillPrintValid} />
                    <Route path="/FileCreation" component={FileCreation} />
                    <Route path="/Error" component={Error} />
                </Switch>
            </Router>
        )
    }
}
