import React, { Component } from 'react';
import ReactToPrint from 'react-to-print';
import '../src/PartumBillPrint.css';
import '../src/Home.js';
import { data, type } from 'jquery';
import history from '../src/History';


let lUrl = window.location.href;

let lvalid = true;
let RandNum = "";

export class PartumBillPrint extends Component {
    static displayName = PartumBillPrint.name;
    constructor() {
        super();

        this.state = {
            lCompanyName: '',
            lCompanyAdd1: '',
            lCompanyAdd2: '',
            lCompanyAdd3: '',
            lCompanygstin: '',
            lCompanyPhno: '',
            lCustomer: '',
            lgstin: '',
            lInvoiceType:'',
            lVehicleNo: '',
            lBillNo: '',
            lBillDate: '',
            lBillType: '',
            lIgst:'',
            lSgst1TaxPer: '',
            lSgst2TaxPer: '',
            lSgst3TaxPer: '',
            lSgst4TaxPer: '',
            lCgst1TaxPer: '',
            lCgst2TaxPer: '',
            lCgst3TaxPer: '',
            lCgst4TaxPer: '',
            lIgst1TaxPer: '',
            lIgst2TaxPer: '',
            lIgst3TaxPer: '',
            lIgst4TaxPer: '',
            lSgst1TaxAmt: '',
            lSgst2TaxAmt: '',
            lSgst3TaxAmt: '',
            lSgst4TaxAmt: '',
            lCgst1TaxAmt: '',
            lCgst2TaxAmt: '',
            lCgst3TaxAmt: '',
            lCgst4TaxAmt: '',
            lIgst1TaxAmt: '',
            lIgst2TaxAmt: '',
            lIgst3TaxAmt: '',
            lIgst4TaxAmt: '',
            lOpeningKilom: '',
            lClosingKilom:'',

            lMileage:'',
            lDiscount: '',
            lIndentNo: '',
            lNetTotal: '',
            lTime: '',
            lCompanylogo: '',
            lItemDt: [],
            lError: '',
            lmessage: ''

        }

    }



    componentWillMount() {
        //let PntAry = [];
        //let lVersionAry = [];
        //let lVersion = "";
        //let lrandnumAry = [];
        //let lRadValue = [];
        //lvalid = true;
        //PntAry = lUrl.split('?')
        //if (PntAry.length > 1) {
        //    lrandnumAry = PntAry[1];
        //    if (lrandnumAry != "") {
        //        lRadValue = lrandnumAry.split('=')
        //        RandNum = lRadValue[1];
        //        /*if (RandNum===)*/
        //    }

        //}

        let Apiurl = "http://support.partumservice.in/api/"
        //let Apiurl = "http://localhost:33744/api/";
        //let Apiurl = "http://103.110.236.163:33744/api/";

        console.log("Apiurl : " + Apiurl)
        let PntAry = [];
        let lTestValue = [];
        let lVersionAry = [];
        let lVersion = "";
        let lRadNumAry = [];
        let lDetail = "";
        lvalid = true;
        PntAry = lUrl.split('?')
        //RandNum = PntAry[1];
        if (PntAry.length > 1) {
            lTestValue = PntAry[1];
            lRadNumAry = lTestValue.split('=')
            RandNum = lRadNumAry[1];
        }

        //PntAry = lUrl.split('?')
        //if (PntAry.length > 1) {
        //    lTestValue = PntAry[1];
        //    if (lTestValue != "") {
        //        lDetail = lTestValue.split('&')
        //    }
        //    if (lDetail.length >= 2) {
        //        lVersionAry = lDetail[0].split('=')
        //        lVersion = lVersionAry[1];
        //        if (lVersion == "1.0.0") {
        //            lRadNumAry = lDetail[1].split('=')
        //            RandNum = lRadNumAry[1];
        //        }
        //        else {
        //            history.push('/Error');
        //        }

        //    }
        //}


        let lTmpAry = [];


        let req1 = {
            method: 'Post',
            //method: 'GET',
            //mode: "no-cors",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                lRandNum: RandNum
            })
        }
        if (RandNum) {

            fetch(Apiurl + 'BillPrinting', req1)
                //fetch(lUrl, req1)            
                .then(response => response.json())
                .then((data) => {
                    if (data.returnmessage == "PartumSuccess") {
                        this.state.lCompanylogo = data.lCompanyLogo

                        if (this.state.lCompanylogo == "1") {
                            this.state.lCompanylogo = "Indian Oil.png";
                        }
                        else if (this.state.lCompanylogo == "2") {
                            this.state.lCompanylogo = "hindustan.png";
                        }
                        else if (this.state.lCompanylogo == "3") {
                            this.state.lCompanylogo = "Bharat_Petroleum.png";
                        }
                        else if (this.state.lCompanylogo == "4") {
                            this.state.lCompanylogo = "Essar_logo.png";
                        }
                        else if (this.state.lCompanylogo == "5") {
                            this.state.lCompanylogo = "Shell_logo.png";
                        }
                        else {
                            this.state.lCompanylogo = "";
                        }

                        this.setState({
                            lCompanyName: data.lCompName,
                            lCompanyAdd1: data.lCompAdd1,
                            lCompanyAdd2: data.lCompAdd2,
                            lCompanyAdd3: data.lCompAdd3,
                            lCompanygstin: data.lCompGSTIN,
                            lCompanyPhno: data.lCompPhno,
                            lCustomer: data.lCustomer,
                            lgstin: data.lGstin,
                            lVehicleNo: data.lVehicleNo,
                            lBillNo: data.lBillNo,
                            lBillDate: data.lBillDate,
                            lBillType: data.lBillType,
                            lInvoiceType: data.lInvoicetype ,
                            lSgst1TaxPer: data.lSGST1TaxPernt,
                            lSgst2TaxPer: data.lSGST2TaxPernt,
                            lSgst3TaxPer: data.lSGST3TaxPernt,
                            lSgst4TaxPer: data.lSGST4TaxPernt,
                            lCgst1TaxPer: data.lCGST1TaxPernt,
                            lCgst2TaxPer: data.lCGST2TaxPernt,
                            lCgst3TaxPer: data.lCGST3TaxPernt,
                            lCgst4TaxPer: data.lCGST4TaxPernt,
                            lIgst1TaxPer: data.lIGST1TaxPernt,
                            lIgst2TaxPer: data.lIGST2TaxPernt,
                            lIgst3TaxPer: data.lIGST3TaxPernt,
                            lIgst4TaxPer: data.lIGST4TaxPernt,
                            lCgst1TaxAmt: data.lCGST1TaxAmnt,
                            lCgst2TaxAmt: data.lCGST2TaxAmnt,
                            lCgst3TaxAmt: data.lCGST3TaxAmnt,
                            lCgst4TaxAmt: data.lCGST4TaxAmnt,
                            lSgst1TaxAmt: data.lSGST1TaxAmnt,
                            lSgst2TaxAmt: data.lSGST2TaxAmnt,
                            lSgst3TaxAmt: data.lSGST3TaxAmnt,
                            lSgst4TaxAmt: data.lSGST4TaxAmnt,
                            lIgst1TaxAmt: data.lIGST1TaxAmnt,
                            lIgst2TaxAmt: data.lIGST2TaxAmnt,
                            lIgst3TaxAmt: data.lIGST3TaxAmnt,
                            lIgst4TaxAmt: data.lIGST4TaxAmnt,
                            lMileage: data.lMileageAmnt,
                            lOpeningKilom:data.lOpeningKm,
                            lClosingKilom:data.lClosingKm,
                            //lsgst: data.lSgst,
                            //lcgst: data.lCgst,
                            lDiscount: data.lDiscount,
                            lIndentNo: data.lIndentNo,
                            lNetTotal: data.lNetTotal,
                            lTime: data.lTime,
                            //lCompanylogo: data.lCompanyLogo,
                            //lItemDt: data.lItemDet
                            //lreturn: data.returnmessage

                        })



                        lTmpAry = data.lItemDet;
                        for (var i = lTmpAry.length; i < 6; i++) {
                            lTmpAry.push({
                                SNo: "",
                                ItemName: "",
                                Qty: "",
                                Rate: "",
                                Amount: ""
                            })
                        }
                        this.setState({ lItemDt: lTmpAry })
                    }
                    else {

                        history.push('/Error');
                    }

                })
        }
        else {

            history.push('/Error');
        }

    }

    //let lCusId = "";
    //let lFileName = "";
    //let lMonth = "";
    //let req = {
    //    method: 'POST',
    //    headers: {
    //        'Content-Type': 'application/json'
    //    },
    //    body: JSON.stringify({
    //        radnum: RandNum

    //    })
    //}
    //fetch(Apiurl + 'PrintBill' , req)
    //    .then(response => response.json())
    //    .then(data => {
    //        if (data.lreturnmessage == 'PartumSuccess')
    //        {
    //            lCusId = data.lCustomerId;
    //            lMonth = data.lBillMonth;
    //            lFileName = data.lFileName;
    //            console.log(lCusId)
    //            console.log(lMonth)
    //            console.log(lFileName)

    //            //Url = `${process.env.PUBLIC_URL}/1101/01/B2021101.json`;
    //            //Url = "../../../public/1101/01/B2021101.json"
    //            Url = "/../1101/01/B2021101.json";
    //            //Url = "./1101/01/B2021101.json";
    //            //Url = `${"/./1101/01/B2021101.json"}`;
    //            //Url = `${process.env.PUBLIC_URL}` + "/" + lCusId + "/" + lMonth + "/" + lFileName + ".json";

    //            //Url = "./"+ lCusId + "/" + lMonth + "/" + lFileName + ".json";

    //            console.log(Url);





    //let req1 = {
    //    method: 'GET',
    //    headers: {
    //        'Content-Type': 'application/json'
    //    },
    //}
    //fetch(Url, req1)
    //    .then(response => response.json())
    //    .then(data => {
    //        console.log(data)

    //        this.setState({
    //            lCompanyName: data.CompName,
    //            lCompanyAdd1: data.CompAdd1,
    //            lCompanyAdd2: data.CompAdd2,
    //            lCompanyAdd3: data.CompAdd3,
    //            lCompanygstin: data.CompGSTIN,
    //            lCompanyPhno: data.CompPhno,
    //            lCustomer: data.Customer,
    //            lgstin: data.GSTIN,
    //            lVehicleNo: data.VehicleNo,
    //            lBillNo: data.BillNo,
    //            lBillDate: data.BillDate,
    //            lBillType: data.BillType,
    //            lsgst: data.Sgst,
    //            lcgst: data.Cgst,
    //            lDiscount: data.Discount,
    //            lIndentNo: data.IndentNo,
    //            lNetTotal: data.NetTotal,
    //            lTime: data.Time,
    //            //lItemDet: data.ItemDet,
    //            lCompanylogo: data.CompanyLogo
    //        })
    //        lTmpAry=data.ItemDet;
    //        for (var i = lTmpAry.length; i < 6; i++) {
    //            lTmpAry.push({
    //                SNo: "",
    //                ItemName: "",
    //                Qty: "",
    //                Rate: "",
    //                Amount: ""
    //            })
    //        }
    //        this.setState({ lItemDet: lTmpAry})
    //    })
    //            }
    //       }).catch(function (error)  {
    //            alert(error)
    //        })



    render() {
        let ShowImage = false;

        if (this.state.lCompanylogo == undefined || this.state.lCompanylogo == null) {
            this.state.lCompanylogo = ""
        }


        var image = "";
        //this.LoadImage();

        if (this.state.lCompanylogo != "") {
            image = require('./Images/' + this.state.lCompanylogo);
            ShowImage = true;
        }
        else {
            image = "";
            ShowImage = false;
        }


        return (
            <div>

                <div className="page">
                    <div>
                        <div class="subpage">
                            <div className="head">
                                <div className="image">
                                    {ShowImage ? (<img src={image} width="100" height="100" />) : null
                                    }
                                </div>
                                {ShowImage ? (<div className="detail1">
                                    <div className="font1"><label>
                                        {`${this.state.lCompanyName.substring(0, 31)}`}
                                    </label></div>
                                    <div className="font2"><label>

                                        {`${this.state.lCompanyAdd1.substring(0, 41)}`}
                                    </label></div>
                                    <div className="font3"><label>
                                        {`${this.state.lCompanyAdd2.substring(0, 40)}`}</label></div>
                                    <div className="font3"><label>

                                        {`${this.state.lCompanyAdd3.substring(0, 30)}`}
                                    </label></div>
                                </div>) : <div className="detail">
                                        <div className="font1"><label> {`${this.state.lCompanyName.substring(0, 45)}`}</label></div>
                                        <div className="font2"><label>  {`${this.state.lCompanyAdd1.substring(0, 50)}`}</label></div>
                                        <div className="font3"><label>  {`${this.state.lCompanyAdd2.substring(0, 41)}`}</label></div>
                                        <div className="font3"><label>  {`${this.state.lCompanyAdd3.substring(0, 30)}`}</label></div>
                                    </div>
                                }
                            </div>
                            <div className="Row1">

                                {
                                    this.state.lCompanygstin ?
                                        <div className="leftali"><label>GSTIN : </label><label>
                                            {`${this.state.lCompanygstin.substring(0, 20)}`}
                                        </label></div> :
                                        <div className="leftali"><label></label><label>
                                        </label></div>
                                }
                                {/*<div className="leftali"><label>GSTIN : </label><label>                                  */}
                                {/*    {`${this.state.lCompanygstin.substring(0, 20)}`}*/}
                                {/*</label></div>*/}
                                {this.state.lInvoiceType ? <div className="centerali1"><label>{this.state.lInvoiceType}</label>
                                </div> : <div className="centerali1"><label></label>
                                </div>}
                                

                                {this.state.lCompanyPhno ? <div className="rightali"><label>Office : </label><label>

                                    {`${this.state.lCompanyPhno.substring(0, 21)}`}
                                </label></div> :
                                    <div className="rightali"><label></label><label>
                                    </label></div>
                                }
                                {/*<div className="rightali"><label>Office : </label><label>*/}

                                {/*    {`${this.state.lCompanyPhno.substring(0, 30)}`}*/}
                                {/*</label></div>*/}
                            </div>

                            <div className="section">
                                <div className="Col">
                                    <div className="Row1">
                                        <div className="Column-c"><label>Customer</label></div>
                                        <div className="Column1"><label>:</label></div>
                                        {/*<div className="Column2" >{this.state.lCustomer}</div>*/}
                                        <div className="Column2">
                                            {`${this.state.lCustomer.substring(0, 17)}`}
                                        </div>
                                        <div className="Column"><label>Bill No</label></div>
                                        <div className="Column1"><label>:</label></div>
                                        <div className="Column3" > <label>{`${this.state.lBillNo.substring(0, 23)}`}</label>
                                        </div>
                                        {/*<div className="Column 3"><label>*/}
                                        {/*    {`${this.state.lBillNo.substring(0,20)}`}</label>*/}
                                        {/*  </div>*/}

                                    </div>
                                    <div className="Row1">

                                        <div className="Column"><label>GSTIN</label></div>
                                        <div className="Column1"><label>:</label></div>
                                        <div className="Column2" ><label>
                                            {`${this.state.lgstin.substring(0, 20)}`}
                                        </label></div>
                                        <div className="Column"><label>Bill Date</label></div>
                                        <div className="Column1"><label>:</label></div>
                                        <div className="Column3"><label>{this.state.lBillDate}</label>  <label></label>  <label></label>    <label></label>  <label>{this.state.lTime}</label></div>

                                    </div>
                                    <div className="Row1">

                                        <div className="Column-v"><label>Vehicle No</label></div>
                                        <div className="Column-col"><label>:</label></div>
                                        <div className="Column-vn"><label>
                                            {`${this.state.lVehicleNo.substring(0, 22)}`}
                                        </label></div>
                                        <div className="Column-bt"><label>Bill Type</label></div>
                                        <div className="Column-b"><label>:</label></div>
                                        <div className="Column3"><label>{this.state.lBillType}</label></div>

                                    </div>
                                </div>
                            </div>

                            <div className="TableWid">
                                <table className="table-bordered">
                                    <thead>
                                        <tr>
                                            <th className="w-1">SNo</th>
                                            <th className="w-2">Item Name</th>
                                            <th className="w-3">Qty</th>
                                            <th className="w-3">Rate</th>
                                            <th className="w-4">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            this.state.lItemDt.map((lItem, Ind) => {
                                                if (lItem.lItemName != undefined) {
                                                    return <tr id={Ind}>
                                                        <td className="center" >{lItem.lSNo}</td>
                                                        {lItem.lItemName ? <td className="leftt" >
                                                            {/*{lItem.lItemName}*/}
                                                            {`${lItem.lItemName.substring(0, 30)}`}
                                                        </td> : <td className="leftt" ></td>}
                                                        
                                                        <td className="rightt">{lItem.lQty}</td>
                                                        {
                                                            lItem.lRate ? <td className="rightt">{lItem.lRate.toFixed(2)}</td> : <td className="rightt"></td>
                                                        }
                                                        {
                                                            lItem.lAmount ? <td className="rightt">{lItem.lAmount.toFixed(2)}</td> : <td className="rightt"></td>
                                                        }
                                                        
                                                        
                                                    </tr>
                                                }
                                                else {
                                                    return <tr id={Ind}>
                                                        <td className="center" >{lItem.lSNo}</td>
                                                        <td className="leftt" >
                                                            {lItem.lItemName}
                                                        </td>
                                                        <td className="rightt">{lItem.lQty}</td>
                                                        <td className="rightt">{lItem.lRate}</td>
                                                        <td className="rightt">{lItem.lAmount}</td>
                                                    </tr>
                                                }

                                            })
                                        }

                                        {/*{*/}
                                        {/*    this.state.lItemDet.map((lItem, Ind) => {*/}
                                        {/*        lTmpAry.push(*/}
                                        {/*            <tr id={Ind}>*/}
                                        {/*                <td className="center">{lItem.SNo}</td>*/}
                                        {/*                <td className="leftt">{lItem.ItemName}</td>*/}
                                        {/*                <td className="rightt">{lItem.Qty}</td>*/}
                                        {/*                <td className="rightt">{lItem.Rate}</td>*/}
                                        {/*                <td className="rightt">{lItem.Amount}</td>*/}
                                        {/*            </tr>*/}
                                        {/*        )*/}
                                        {/*        //if (Ind === this.state.lItemDet.length - 1) {*/}
                                        {/*        //    for (let i = 6; i > this.state.lItemDet.length; i--) {*/}
                                        {/*        //        lTmpAry.push( <tr>*/}
                                        {/*        //            <td className="center">1</td>*/}
                                        {/*        //            <td className="leftt">1</td>*/}
                                        {/*        //            <td className="rightt">1</td>*/}
                                        {/*        //            <td className="rightt">1</td>*/}
                                        {/*        //            <td className="rightt">1</td>*/}
                                        {/*        //        </tr>)*/}
                                        {/*        //    }*/}
                                        {/*        //}*/}
                                        {/*        return lTmpAry*/}
                                        {/*    })*/}
                                        {/*}   */}




                                    </tbody>
                                </table>
                            </div>
                            {/*<div className="space1">*/}
                            {/*    <div className="row11">*/}
                            {/*        {this.state.lsgst ? <div className="col14"><label>SGST : </label><label className="col14s">{this.state.lsgst}</label></div> :*/}
                            {/*            <div className="col14"><label></label><label className="col14s"></label></div>}*/}
                            {/*        */}{/*<div className="col14"><label>SGST 6% : </label><label className="col14s">{this.state.lsgst}</label></div>*/}

                            {/*        {this.state.lcgst ? <div className="col15"><label>CGST : </label><label className="col15s">{this.state.lcgst}</label></div> :*/}
                            {/*            <div className="col15"><label></label><label className="col15s"></label></div>}*/}

                            {/*        */}{/*<div className="col15"><label>CGST 6% : </label>*/}
                            {/*        */}{/*    <label className="col15s">{this.state.lcgst}</label>*/}
                            {/*        */}{/*</div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="row-gst">
                                 <div className="col">
                                    <div>

                                        {
                                            this.state.lIgst1TaxPer ?
                                                <><label className="wid-sgst1">IGST</label><label className="wid-sgst2">{this.state.lIgst1TaxPer}%</label> </> :
                                                this.state.lSgst1TaxPer ? <> <label className="wid-sgst1">SGST</label> <label className="wid-sgst2">{this.state.lSgst1TaxPer}%</label><label className="wid-col">:</label> </> : <label className="wid-sgst2"></label>
                                        }

                                        
                                      

                                        {this.state.lIgst1TaxAmt ? <><label className="wid-col">:</label>
                                            <label className="txt-right">{this.state.lIgst1TaxAmt.toFixed(2)}</label></> : this.state.lSgst1TaxAmt ? <> <label className="txt-right">{this.state.lSgst1TaxAmt.toFixed(2)}</label> </> : <label className=""></label>}


                                      
                                        
                                    </div>
                                    <div className="">
                                        {
                                            this.state.lIgst2TaxPer ?
                                                <><label className="wid-sgst1">IGST</label><label className="wid-sgst2">{this.state.lIgst2TaxPer}%</label> </> :
                                                this.state.lSgst2TaxPer ? <> <label className="wid-sgst1">SGST</label> <label className="wid-sgst2">{this.state.lSgst2TaxPer}%</label><label className="wid-col">:</label> </> : <label className="wid-sgst2"></label>
                                        }


                                        {this.state.lIgst2TaxAmt ? <><label className="wid-col">:</label>
                                            <label className="txt-right">{this.state.lIgst2TaxAmt.toFixed(2)}</label></> : this.state.lSgst2TaxAmt ? <> <label className="txt-right">{this.state.lSgst2TaxAmt.toFixed(2)}</label> </> : <label className=""></label>}

                                    </div>
                                    <div className="">
                                        {
                                            this.state.lIgst3TaxPer ?
                                                <><label className="wid-sgst1">IGST</label><label className="wid-sgst2">{this.state.lIgst3TaxPer}%</label> </> :
                                                this.state.lSgst3TaxPer ? <> <label className="wid-sgst1">SGST</label> <label className="wid-sgst2">{this.state.lSgst3TaxPer}%</label><label className="wid-col">:</label> </> : <label className="wid-sgst2"></label>
                                        }


                                        {this.state.lIgst3TaxAmt ? <><label className="wid-col">:</label>
                                            <label className="txt-right">{this.state.lIgst3TaxAmt.toFixed(2)}</label></> : this.state.lSgst3TaxAmt ? <> <label className="txt-right">{this.state.lSgst3TaxAmt.toFixed(2)}</label> </> : <label className=""></label>}


                                  </div>
                                    <div className="">
                                        {
                                            this.state.lIgst4TaxPer ?
                                                <><label className="wid-sgst1">IGST</label><label className="wid-sgst2">{this.state.lIgst4TaxPer}%</label> </> :
                                                this.state.lSgst4TaxPer ? <> <label className="wid-sgst1">SGST</label> <label className="wid-sgst2">{this.state.lSgst4TaxPer}%</label><label className="wid-col">:</label> </> : <label className="wid-sgst2"></label>
                                        }


                                        {this.state.lIgst4TaxAmt ? <><label className="wid-col">:</label>
                                            <label className="txt-right">{this.state.lIgst4TaxAmt.toFixed(2)}</label></> : this.state.lSgst4TaxAmt ? <> <label className="txt-right">{this.state.lSgst4TaxAmt.toFixed(2)}</label> </> : <label className=""></label>}

                                    </div>
                                </div> 
                                    
                                        {/*<div className="col">*/}
                                        {/*    */}{/*<div>*/}


                                        {/*    */}{/*    <label className="wid-sgst1">SGST</label>*/}
                                        {/*    */}{/*    {this.state.lSgst1TaxPer ?*/}
                                        {/*    */}{/*        <label className="wid-sgst2">{this.state.lSgst1TaxPer}%</label> : <label className="wid-sgst2"></label>*/}
                                        {/*    */}{/*    }*/}
                                                
                                        {/*    */}{/*    <label className="wid-col">:</label>*/}
                                        {/*    */}{/*    {this.state.lSgst1TaxAmt ?*/}
                                        {/*    */}{/*        <label className="">{this.state.lSgst1TaxAmt}</label> :*/}
                                        {/*    */}{/*        <label className=""></label>*/}
                                        {/*    */}{/*    }*/}
                                        {/*    */}{/*</div>*/}
                                        {/*    <div className="">*/}
                                        {/*        <label className="wid-sgst1">SGST</label>*/}
                                        {/*        {this.state.lSgst2TaxPer ?*/}
                                        {/*            <label className="wid-sgst2">{this.state.lSgst2TaxPer}%</label> : <label className="wid-sgst2"></label>*/}
                                        {/*        }*/}
                                        {/*        */}{/*<label>%</label>*/}
                                        {/*        <label className="wid-col">:</label>*/}
                                        {/*        {this.state.lSgst2TaxAmt ?*/}
                                        {/*            <label className="">{this.state.lSgst2TaxAmt}</label> :*/}
                                        {/*            <label className=""></label>*/}
                                        {/*        }*/}
                                        {/*    </div>*/}
                                        {/*    <div className="">*/}
                                        {/*        <label className="wid-sgst1">SGST</label>*/}
                                        {/*        {this.state.lSgst3TaxPer ?*/}
                                        {/*            <label className="wid-sgst2">{this.state.lSgst3TaxPer}%</label> : <label className="wid-sgst2"></label>*/}
                                        {/*        }*/}
                                        {/*        */}{/*<label>%</label>*/}
                                        {/*        <label className="wid-col">:</label>*/}
                                        {/*        {this.state.lSgst3TaxAmt ?*/}
                                        {/*            <label className="">{this.state.lSgst3TaxAmt}</label> :*/}
                                        {/*            <label className=""></label>*/}
                                        {/*        }*/}
                                        {/*    </div>*/}
                                        {/*    <div className="">*/}
                                        {/*        <label className="wid-sgst1">SGST</label>*/}
                                        {/*        {this.state.lSgst4TaxPer ?*/}
                                        {/*            <label className="wid-sgst2">{this.state.lSgst4TaxPer}%</label> : <label className="wid-sgst2"></label>*/}
                                        {/*        }*/}
                                        {/*        */}{/*<label>%</label>*/}
                                        {/*        <label className="wid-col">:</label>*/}
                                        {/*        {this.state.lSgst4TaxAmt ?*/}
                                        {/*            <label className="">{this.state.lSgst4TaxAmt}</label> :*/}
                                        {/*            <label className=""></label>*/}
                                        {/*        }*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="col">
                                            <div>

                                               
                                        {this.state.lCgst1TaxPer ?
                                             <><label className="wid-sgst1">CGST</label>
                                                    <label className="wid-sgst2">{this.state.lCgst1TaxPer}%</label></> : <label className="wid-sgst2"></label>
                                            }
                                                {/*<label>%</label>*/}
                                                
                                        {this.state.lCgst1TaxAmt ?
                                            <><label className="wid-col">:</label>
                                                <label className="txt-right1" >{this.state.lCgst1TaxAmt.toFixed(2)}</label> </> :
                                            <label></label>
                                                }

                                            </div>
                                            <div>
                                               
                                        {this.state.lCgst2TaxPer ?
                                             <><label className="wid-sgst1">CGST</label>
                                                    <label className="wid-sgst2">{this.state.lCgst2TaxPer}%</label></>: <label className="wid-sgst2"></label>
                                                }
                                                {/*<label className="wid-per">%</label>*/}
                                               
                                        {this.state.lCgst2TaxAmt ?
                                            <><label className="wid-col">:</label>
                                                <label className="txt-right1">{this.state.lCgst2TaxAmt.toFixed(2)}</label></> :
                                            <label></label>
                                                }
                                            </div>
                                            <div>
                                               
                                        {this.state.lCgst3TaxPer ?
                                             <><label className="wid-sgst1">CGST</label>
                                                    <label className="wid-sgst2">{this.state.lCgst3TaxPer}%</label></>: <label className="wid-sgst2"></label>
                                                }
                                                {/*<label className="wid-per">%</label>*/}
                                               
                                        {this.state.lCgst3TaxAmt ?
                                             <><label className="wid-col">:</label>
                                                <label className="txt-right1">{this.state.lCgst3TaxAmt.toFixed(2)}</label></> :
                                            <label></label>
                                                }
                                            </div>
                                            <div className="">
                                                
                                        {this.state.lCgst4TaxPer ?
                                            <><label className="wid-sgst1">CGST</label>
                                              <label className="wid-sgst2">{this.state.lCgst4TaxPer}%</label></> : <label className="wid-sgst2"></label>
                                        }
                                                {/*<label className="wid-per">%</label>*/}
                                               
                                        {this.state.lCgst4TaxAmt ?
                                             <><label className="wid-col">:</label>
                                                <label className="txt-right1">{this.state.lCgst4TaxAmt.toFixed(2)}</label></> :
                                            <label></label>
                                                }
                                            </div>
                                        </div>                                   
                                    
                                
                                
                                <div className="col1">
                                    <div className="discnt">{this.state.lDiscount ?<> <label>Discount : </label> <label style={{ paddingLeft: '4px' }}>{this.state.lDiscount.toFixed(2)}</label></>:  <label style={{ paddingLeft: '4px' }}></label>}
                                        
                                        
                                    </div>
                                    <div className="net-tot">
                                        
                                        
                                        {
                                        this.state.lNetTotal ?
                                                <><label className="leftalit"  style={{ fontSize: '26px' }}>Net Total</label>
                                                    <label className="rightalit" style={{ fontSize: '26px' }}  style={{ textAlignLast: 'right' }}>{this.state.lNetTotal.toFixed(2)}</label></> : <label style={{ fontSize: '26px' }}></label>
                                        }
                                       
                                    </div>
                                    
                                </div>
                                
                                {/*<div className="col"></div>*/}
                            </div>
                            <div className="row-km">
                                <div className="col1-o">
                                    {this.state.lOpeningKilom ? <div><div><label className="wid-openkm">Opening Km </label><label className="wid-col1">:</label> <label className="txt-rightopen">{this.state.lOpeningKilom}</label></div>


                                    </div> :
                                        <div><div><label className="wid-openkm"></label><label className="wid-col1"></label><label className="txt-rightopen"></label></div></div>
                                    }
                                    {
                                        this.state.lIndentNo ? <div><label className="wid-openkm">Indent No  </label><label className="wid-col1">:</label><label className="txt-rightopen"> {this.state.lIndentNo}</label></div> :
                                            <div><label className="wid-openkm"></label><label className="wid-col1"></label><label className="txt-rightopen"></label></div>
                                    }
                                    
                                </div>
                                <div className="col1-m">
                                    {
                                        this.state.lClosingKilom ? <div><label className="wid-closekm">Closing Km</label><label className="wid-col1">:</label> <label className="txt-rightopen">{this.state.lClosingKilom}</label></div> :
                                            <div><label className="wid-closekm"></label><label className="wid-col1"></label><label className="txt-rightopen"></label></div>
                                        }                      
                                       
                                    {this.state.lMileage ? <div><div><label className="wid-closekm">Mileage</label><label className="wid-col1">:</label> <label className="txt-rightopen">{this.state.lMileage.toFixed(2)}</label></div>


                                    </div> :
                                        <div><div><label className="wid-closekm"></label><label className="wid-col1"></label><label className="txt-rightopen"></label></div></div>
                                    }
                                    
                                </div>
                                <div className="col">
                                </div>
                            </div>
                            {/*<div className="row11">*/}
                            {/*    {this.state.lOpeningKilom ? <div className="sub-heading11"><div><label>Opening Km </label>  <label >:</label> <label >{this.state.lOpeningKilom.toFixed(2)}</label></div>*/}


                            {/*    </div> :*/}
                            {/*        <div className="sub-heading11"><div><label></label><label></label></div></div>*/}
                            {/*    }*/}
                            {/*    {*/}
                            {/*        this.state.lClosingKilom ? <div className="sub-heading11"><label>Closing Km : </label> <label> {this.state.lClosingKilom.toFixed(2)}</label></div> :*/}
                            {/*            <div className="col11"><label></label><label></label></div>*/}
                            {/*    }*/}

                            {/*</div>*/}
                            
                            {/*<div className="row11m">*/}
                            {/*    {this.state.lMileage ? <div className="sub-heading11"><div><label className="wid-km">Milage </label>  <label className="wid-col1">:</label><label>{this.state.lMileage.toFixed(2)}</label></div>*/}


                            {/*    </div> :*/}
                            {/*        <div className="sub-heading11"><div><label></label><label></label></div></div>*/}
                            {/*    }*/}
                            {/*    */}{/*<div style={{ width:'30%',border:'1px solid red' }}></div>*/}
                            {/*    {*/}
                            {/*        this.state.lIndentNo ? <div className=""><label>Indent No : </label> <label> {this.state.lIndentNo}</label></div> :*/}
                            {/*            <div ><label></label><label></label></div>*/}
                            {/*    }*/}

                            {/*    </div>*/}
                               
                          
                        </div>
                          {/*<div className="col11"><label>Indent No : </label><label> {this.state.lIndentNo}</label></div>*/}
                            {/*<div className="col12"><label>Net Total</label></div>*/}
                            {/*<div className="col13"><label>{this.state.lNetTotal}</label></div>*/}
                            {/*<div className="row11">*/}
                            {/*    <p className="sub-heading12">*/}
                            {/*        <div><label>{this.state.lTime}%</label></div></p>*/}
                            {/*</div>*/}
                    </div> </div>
                {/*<div className="notfound"><label>{this.state.lError}</label>*/}
                {/*</div>*/}



            </div>
        );
    }
}

class Bill extends Component {


    constructor() {
        super();
        this.state = {
            lViewPrint: false,
            /*lError:''*/
        }
    }
    ReadFile() {
        this.setState({ lViewPrint: true })
    }
    //componentWillMount() {
    //    let PntAry = [];
    //    let lrandnumAry = [];
    //    let lRadValue = [];
    //    lvalid = true;
    //    PntAry = lUrl.split('?')
    //    if (PntAry.length > 1) {
    //        lrandnumAry = PntAry[1];
    //        if (lrandnumAry != "") {
    //            lRadValue = lrandnumAry.split('=')
    //            RandNum = lRadValue[1];
    //            /*if (RandNum===)*/
    //        }
    //    }
    //    if (RandNum == "") {
    //        lvalid = false;
    //    }

    //    let req1 = {
    //        method: 'Post',
    //        headers: {
    //            'Content-Type': 'application/json'
    //        },
    //        body: JSON.stringify({
    //            lRandNum: RandNum
    //        })
    //    }
    //    if (RandNum) {
    //        fetch(Apiurl + 'BillPrinting', req1)
    //            .then(response => response.json())
    //            .then((data) => {
    //                if (data.returnmessage != "PartumSuccess") {
    //                    lvalid = false;
    //                /* this.setState({ lError: "Link is not Valid...!" })*/
    //                    history.push('/Error');
    //                }
    //            })
    //    }
    //    else {
    //        //this.setState({ lError: "Link is not Valid...!" })
    //        //lvalid = false;
    //        history.push('/Error');
    //    }
    //}

    render() {
        return (
            <div>
                {/*{*/}
                {/*    lvalid ?*/}
                <div>
                    <ReactToPrint
                        trigger={() =>

                            <button className="button1">Print</button>
                        }
                        content={() => this.componentRef}

                    />


                </div>
                {/*        :*/}
                {/*        <div></div>*/}
                {/*}*/}
                <PartumBillPrint ref={el => (this.componentRef = el)} />
            </div>


        );
    }
}

export default Bill

//class BillPrint extends Component {


//    constructor() {
//        super();
//        this.state = {
//            lViewPrint: false
//        }
//    }
//    ReadFile() {
//        this.setState({ lViewPrint:true })

//    }

//    render() {
//        return (

//            <div>

//                <ReactToPrint
//                    trigger={() =>

//                        <button className="button1">Print</button>
//                    }
//                    content={() => this.componentRef}
//                />
//                <PartumBillPrint ref={el => (this.componentRef = el)} />

//            </div>


//        );
//    }
//}

//export default BillPrint