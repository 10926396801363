import React, { Component } from 'react';
import '../src/Home.js';
import history from '../src/History';
import { error } from 'jquery';


let lVar1 = window.location.href 
let lCusId = "";
let lMonth = "";
let FileName = "";
//let Apiurl = "http://localhost:33744/api/";
let Apiurl = "http://support.partumservice.in/api/"
//let Apiurl = "http://103.110.236.163:33744/api/";

localStorage.setItem('Apiurl', Apiurl);
class FileCreation extends Component {

    constructor() {
        super();
        this.state = {
            lreturn: ''

        }
    }




    componentDidMount() {
        let lVersionAry = [];
        let lVersion = "";
        let TestAry = [];
        let lTestValue = [];
        let lDetail = "";
        let lRadNumAry = [];
        let lCusIdAry = [];
        let lMonthAry = [];
        let lFileNameAry = [];
        let lRadnm = "";
        let lBillData = [];

        TestAry = lVar1.split('?')
    
        if (TestAry.length > 1) {
            lTestValue = TestAry[1];
            if (lTestValue != "") {                 
                lDetail = lTestValue.split(';;;')
            }
            if (lDetail.length >= 6) {
                lVersionAry = lDetail[0].split(':')
                lVersion = lVersionAry[1];
                if (lVersion == "1.0.0") {
                    lRadNumAry = lDetail[1].split(':')
                    lCusIdAry = lDetail[2].split(':')
                    lMonthAry = lDetail[3].split(':')
                    lFileNameAry = lDetail[4].split(':')
                    lRadnm = lRadNumAry[1];
                    lCusId = lCusIdAry[1];
                    lMonth = lMonthAry[1];
                    FileName = lFileNameAry[1];
                    lBillData = lDetail[5];
                }
                
            }
           
        }
       
        lBillData = decodeURIComponent(lBillData);
        
       
        console.log("APIURL :" + Apiurl)

        console.log("Fetch Url :" + lVar1)
        

        let req = {
            //method: 'Post',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            //body: JSON.stringify({
            //    randnum: lRadnm,
            //    lTestingAry: lBillData,
            //    lCustomerId: lCusId,
            //    lCustomerMonth: lMonth,
            //    lCusFileName: FileName,
            //    lcurVersion:lVersion
            //})
        }
        console.log("REQ :" + req)

        //fetch(Apiurl + 'FileCreating', req)
        fetch(lVar1, req)
            .then(response => response.json())
            .then(data => {
                console.log("RETURN :" + data)
                //if (data.returnmessage != 'PartumSuccess') {
                //    alert(data.returnmessage)
                //}
                //else {
                //    alert(data.returnmessage)
                //}
                //alert(data.returnmessage)
                console.log(data.returnmessage)
                let lMessage = JSON.stringify(data.returnmessage)

                console.log("Message :" + lMessage)
                this.setState({ lreturn: lMessage});
               
            }).catch((error) => {
                //alert(error)
                console.log(error)
            })
     }
     render() {
         return (
             <div>
                 {this.state.lreturn}
              </div>
        );
     }
}
export default FileCreation;