import React, { Component } from 'react';
import { HomePage } from '../Home';
import PartumBillPrint from '../PartumBillPrint';
import Routes from '../Routes';


export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
        <div>
            <Routes />
            {/*<HomePage/>*/}
      </div>
    );
  }
}
