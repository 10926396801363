import React, { Component } from 'react';
import '../src/Home.js';
class Error extends Component {
    constructor() {
        super();
        this.state = {
            lError:''
        }
    }
   
    render() {
        return (
            <div style={{
                width: '700px', padding: '40px', marginTop: '15%', marginLeft: '26%', marginRight: '25%', fontSize:'1em'
            }}>

                <label style={{ textAlign: 'center', color: 'darkblue', fontFamily: 'Tahoma', paddingLeft: '150px', fontSize: '30px', whiteSpace: 'nowrap' }}>Link was Expired ! </label><label style={{ textAlign: 'center', color: 'black', fontFamily: 'Tahoma', fontSize: '20px', whiteSpace: 'nowrap' }} >Software developed and maintained by Partum Softwares Pvt Ltd</label></div >
        );

}
}
export default Error