import React, { Component } from 'react';
import history from '../src/History';
import { render } from 'react-dom';
import { Redirect } from 'react-router-dom';
import '../src/Home.js';

let lUrl = window.location.href;
let Apiurl = localStorage.getItem('Apiurl')
class BillPrintValid extends Component {
    constructor()
    {
        super();
        this.state = {
            lreturn: ''

        }
    }
    
    componentWillMount() {

        let PntAry = [];
        let lTestValue = [];
        let lVersionAry = [];
        let lVersion = "";
        let lRadNumAry = [];
        let lDetail = "";
        let lRandNum = "";
        PntAry = lUrl.split('?')
        if (PntAry.length > 1) {
            lTestValue = PntAry[1];
            if (lTestValue != "") {
                lDetail = lTestValue.split('&')
            }
            if (lDetail.length >= 2) {
                lVersionAry = lDetail[0].split('=')
                lVersion = lVersionAry[1];
                
                    if (lVersion == "1.0.0") {
                        lRadNumAry = lDetail[1].split('=')
                        lRandNum = lRadNumAry[1];
                    }
                
                //else {
                //     history.push('/Error');
                //}

            }
           
        }
        
         let req1 = {
             method: 'GET',
             headers: {
                 'Content-Type': 'application/json'
             },
         }
         fetch(lUrl, req1)
             .then(response => response.json())
             .then(data => {
                
                 this.setState({ lreturn: data });
                 
             })
    }
    render() {
        return (
            <div>
                {this.state.lreturn}
            </div>
        );
    }

}
export default BillPrintValid