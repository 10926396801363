import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import Routes from './Routes';
import { Router, Switch, Route } from "react-router-dom";
import history from '../src/History';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
        {/*<App />*/}
        <Routes />
  </BrowserRouter>,
    //<Router history={history}>
    //    <Routes />
    //    </Router>,

  rootElement);

registerServiceWorker();

